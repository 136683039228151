import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Link from 'next/link';
import { get, getOr, compose } from 'lodash/fp';
import { useRouter, withRouter } from 'next/router';
import { connect } from '../../store/store';
import {
  EXTERNAL_PAGE,
  HOUDINI_PRODUCT,
  REUSE_PRODUCT,
  BLOCK_PAGE,
  EDITORIAL_PAGE,
  MAIN_CATEGORY_PAGE,
  CATEGORY_PAGE,
  GIFT_CARD,
  MADE_TO_ORDER_PRODUCT,
} from '../../constants/cmsTypes';

const LocalizedLinkComponent = ({
  market,
  as,
  router,
  page,
  data,
  children,
}) => {
  const { locale } = useRouter();

  // External links needs to be handled differently, but still by the
  // Link component, and not an anchor element, for style inheritance
  // to work properly.
  if (page === EXTERNAL_PAGE) {
    // External links might be internal links containg filter parameters. Handle that
    const containsFilter = as.indexOf('?filter') > -1;

    if (containsFilter) {
      const asForFilterUrl =
        as.indexOf(process.env.NEXT_PUBLIC_SITE_BASE_URL) > -1
          ? as.replace(process.env.NEXT_PUBLIC_SITE_BASE_URL, '')
          : as;

      return (
        <Link as={asForFilterUrl} href={asForFilterUrl} passHref legacyBehavior>
          {children}
        </Link>
      );
    }

    return (
      <Link as={as} href={as} passHref target="_blank" legacyBehavior>
        {children}
      </Link>
    );
  }

  // Get preview mode from the router.
  const isInPreviewMode = getOr(false, 'query.preview', router) === 'true';

  let pageType = page;

  // Products of the type "Made To Order Product" and "HoudiniProduct" should use the same product page
  if (page === MADE_TO_ORDER_PRODUCT) pageType = HOUDINI_PRODUCT;

  // Products of the type "ReuseProduct" and "HoudiniProduct" should use the same product page
  if (page === REUSE_PRODUCT) pageType = HOUDINI_PRODUCT;

  // Page of type "Product" should be "HoudiniProduct"
  if (page === 'Product') pageType = HOUDINI_PRODUCT;

  // Page of type "GiftCard" should be "HoudiniProduct" -> GiftCard page is wrapped in HoudiniProduct
  if (page === GIFT_CARD) pageType = HOUDINI_PRODUCT;

  if (
    page === BLOCK_PAGE ||
    page === MAIN_CATEGORY_PAGE ||
    page === CATEGORY_PAGE
  )
    pageType = EDITORIAL_PAGE;

  // Append preview bool to the visible URL if we are in
  // preview mode.
  const linkAs =
    (as ? `/${locale}/${as}` : `/${locale}`) +
    (isInPreviewMode ? `?preview=true` : '');

  // Append preview bool to the hidden URL if we are in preview
  // mode.
  const serialized = isInPreviewMode
    ? qs.stringify({ preview: true, locale, ...data })
    : qs.stringify({ locale, ...data });

  // const linkHref = `/${pageType}?${serialized}`;

  return (
    <Link as={linkAs} href={linkAs} passHref legacyBehavior>
      {children}
    </Link>
  );
};

LocalizedLinkComponent.defaultProps = {
  data: {},
  page: '',
  as: '',
};

export const LocalizedLinkPropType = {
  as: PropTypes.string,
  router: PropTypes.object.isRequired,
  page: PropTypes.oneOf([
    'BlockPage',
    'CategoryPage',
    'Checkout',
    'Confirm',
    'CustomerServicePage',
    'EditorialListPage',
    'EditorialPage',
    'ExternalLink',
    'GiftCard',
    'HoudiniProduct',
    'MainCategoryPage',
    'MyPagesOrderDetailsPage',
    'MyPagesOrdersPage',
    'MyPagesRemoveAccountPage',
    'MyPagesStartPage',
    'MyPagesUserDetailsPage',
    'NewsPage',
    'NotFoundPage',
    'Product',
    'ReuseProduct',
    'SearchResultPage',
    'StartPage',
    'StoreListPage',
  ]),
  data: PropTypes.object,
  children: PropTypes.node.isRequired,
};

LocalizedLinkComponent.propTypes = LocalizedLinkPropType;

export const LocalizedLink = compose(
  withRouter,
  connect
)(LocalizedLinkComponent);
