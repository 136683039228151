import React from 'react';
import { filter, map, find, compose } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Conditional } from 'components/Conditional/Conditional';
import { CategoryItem } from './CategoryItem';
import { Icon } from 'components/Icon/Icon';
import { ICONS } from 'constants/icons';
import { LocalizedLink } from 'components/LocalizedLink/LocalizedLink';
import { String } from 'components/String/String';
import { connect } from 'store/store';
import { removeViewstate, setViewstate } from 'store/actions/actions';
import { LOGIN, MARKET_SELECT, NEWSLETTER } from 'constants/modals';
import { newsletterManualOpenEvent } from 'helpers/gtm';
import {
  BackButton,
  BackButtonText,
  ChangeLanguage,
  Container,
  CurrentCategory,
  CurrentCategoryCount,
  CurrentCategoryLink,
  CurrentCategoryText,
  HeaderLogin,
  HeaderPageContainer,
  HeaderPages,
  HeaderPagesLinkTag,
  Level,
  Slider,
} from './styles';
import { withUserData } from 'hoc/withUserData';
import { MY_PAGES_START_PAGE } from 'constants/cmsTypes';
import CountryFlag from 'components/Header/CountryFlag';

class NavigationDrawerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 0,
      navigation: [{ id: 'base', items: this.props.items }],
    };

    this.drawerContainer = React.createRef();

    this.onCategoryClick = this.onCategoryClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.handleNewsletterModal = this.handleNewsletterModal.bind(this);
    this.handleCloseAndOpen = this.handleCloseAndOpen.bind(this);
  }

  onCategoryClick(items) {
    let newitems;
    const filterChildren = (i, id) => {
      return find((x) => x.id === id, i);
    };

    if (this.state.level === 0) {
      newitems = filterChildren(items.children, items.id);
      const accessories = filterChildren(items.children, 'accessories');
      if (!!accessories && !filterChildren(newitems.children, 'accessories')) {
        newitems.children.push(accessories);
      }
    } else {
      newitems = items;
    }

    const nav = [...this.state.navigation];
    nav[this.state.level + 1] = {
      id: newitems.id,
      title: newitems.title,
      items: newitems.children,
      count: newitems.count,
      campaigns: newitems.campaigns,
      type: newitems.type,
    };
    this.setState((prevState) => ({
      level: prevState.level + 1,
      navigation: nav,
    }));

    if (this.drawerContainer.current) {
      this.drawerContainer.current.scrollTop = 0;
    }
  }

  onBackClick() {
    this.setState((prevState) => ({ level: prevState.level - 1 }));

    if (this.drawerContainer.current) {
      this.drawerContainer.current.scrollTop = 0;
    }
  }

  handleNewsletterModal() {
    newsletterManualOpenEvent();
    this.handleCloseAndOpen(NEWSLETTER);
  }

  handleLoginModal() {
    this.props.setViewstate('activeModal', LOGIN);
  }

  handleCloseAndOpen(modal) {
    this.props.removeViewstate('activeModal');
    setTimeout(() => {
      this.props.setViewstate('activeModal', modal);
    }, 475);
  }

  render() {
    const { country, language, topBarPages } = this.props;

    const headerPages = topBarPages.map((page) => (
      <HeaderPageContainer key={page.id}>
        <LocalizedLink
          key={page.id}
          page={page.type}
          as={page.id}
          data={{ id: page.id }}
          passHref
        >
          <HeaderPagesLinkTag>{page.title}</HeaderPagesLinkTag>
        </LocalizedLink>
      </HeaderPageContainer>
    ));

    return (
      <Container ref={this.drawerContainer}>
        <Slider
          level={this.state.level}
          itemslength={this.state.navigation.length}
        >
          {this.state.navigation.map((level, index) => {
            const isBaseLevel = index === 0;

            return (
              <Level
                key={level.id}
                level={this.state.level}
                isBaseLevel={isBaseLevel}
              >
                <Conditional show={!isBaseLevel}>
                  <BackButton onClick={() => this.onBackClick()}>
                    <Icon icon={ICONS.ARROW} width={12} height={12} />
                    <BackButtonText>
                      <String id="mobileMenuBackLabel" />
                    </BackButtonText>
                  </BackButton>
                  <CurrentCategory>
                    <LocalizedLink
                      as={`${level.id}`}
                      page={level.type}
                      data={{ id: level.id }}
                    >
                      <CurrentCategoryLink>
                        <CurrentCategoryText>
                          <String id="mobileMenuSeeAll" />
                          {` ${level.title}`}
                        </CurrentCategoryText>
                        {level.count ? (
                          <CurrentCategoryCount>{`(${level.count})`}</CurrentCategoryCount>
                        ) : (
                          false
                        )}
                      </CurrentCategoryLink>
                    </LocalizedLink>
                  </CurrentCategory>
                </Conditional>

                {map(
                  (navItem) => (
                    <CategoryItem
                      key={navItem.title}
                      categoryItem={navItem}
                      onClick={this.onCategoryClick}
                      showCount={!isBaseLevel}
                    />
                  ),
                  filter(
                    (i) => i.type !== 'HoudiniProduct' && i.id,
                    level.items
                  )
                )}

                <Conditional show={isBaseLevel}>
                  <HeaderPages>
                    <HeaderPageContainer>
                      <ChangeLanguage
                        onClick={() => this.handleNewsletterModal()}
                      >
                        <p>
                          <String id="newsletterButtonLabel" />
                        </p>
                      </ChangeLanguage>
                    </HeaderPageContainer>

                    <HeaderPageContainer>
                      <ChangeLanguage
                        onClick={() => this.handleCloseAndOpen(MARKET_SELECT)}
                      >
                        <CountryFlag mobile />
                      </ChangeLanguage>
                    </HeaderPageContainer>

                    {headerPages}

                    {this.props.myPagesEnabled && (
                      <HeaderPageContainer>
                        {this.props.userHook.user &&
                        this.props?.userHook?.user?.customerId !==
                          '_everyone' ? (
                          <HeaderLogin>
                            <LocalizedLink
                              as="my-pages"
                              page={MY_PAGES_START_PAGE}
                              data={{ id: 'my-pages' }}
                            >
                              <String id="MyPagesMyAccount" />
                            </LocalizedLink>
                          </HeaderLogin>
                        ) : (
                          <HeaderLogin onClick={() => this.handleLoginModal()}>
                            <Icon
                              icon={ICONS.USER}
                              size={18}
                              pathSettings={{
                                fillRule: 'evenodd',
                                clipRule: 'evenodd',
                                fill: 'black',
                                stroke: 'black',
                                strokeWidth: '.2',
                                strokeLinecap: 'round',
                              }}
                              mr={6}
                            />
                            <String id="MyPagesLogin" />
                          </HeaderLogin>
                        )}
                      </HeaderPageContainer>
                    )}
                  </HeaderPages>
                </Conditional>
              </Level>
            );
          })}
        </Slider>
      </Container>
    );
  }
}

NavigationDrawerComponent.defaultProps = {
  items: [],
  topBarPages: [],
  userHook: {},
  myPagesEnabled: false,
};

NavigationDrawerComponent.propTypes = {
  setViewstate: PropTypes.func.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  topBarPages: PropTypes.arrayOf(PropTypes.object),
  userHook: PropTypes.object,
  myPagesEnabled: PropTypes.bool,
};

export const NavigationDrawer = compose(withUserData, connect)(
  NavigationDrawerComponent,
  {
    setViewstate,
    removeViewstate,
  }
);
