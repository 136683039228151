import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { FlagComponent } from 'components/FlagComponent/FlagComponent';

const CountryFlag = ({ mobile }) => {
  const { locale } = useRouter();
  const markets = useSelector((state) => state?.markets);
  const { country, language } = useSelector((state) => state?.viewState);
  const [countryName, setCountryName] = useState(null);
  const [languageName, setLanguageName] = useState(null);

  useEffect(() => {
    if (markets.data) {
      const matchedCountry = markets.data.find(
        ({ countryCode }) => countryCode === country.toLowerCase()
      );

      if (matchedCountry) {
        setCountryName(matchedCountry.countryName);
        setLanguageName(
          matchedCountry.languages.find(
            ({ languageCode }) => languageCode === language
          ).title
        );
      }
    }
  }, [markets, country]);

  return (
    <>
      <FlagComponent
        countryCode={country || locale.split('-')[1].toUpperCase()}
        mr={mobile ? 8 : 4}
      />
      <p>
        {countryName || locale.toUpperCase()} ({languageName})
      </p>
    </>
  );
};

export default CountryFlag;
