import React from 'react';
import { string, number, object } from 'prop-types';
import { FormLabel, FormRow, GenericInput, ValidationError } from './styles';

export const Input = (
  props,
  { width = 1, type = 'text', placeholder = undefined, error = {} }
) => {
  const { label, name, register } = props;

  return (
    <FormRow width={width} name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <GenericInput
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        {...register}
        error={error && error.message}
      />
      {error && <ValidationError name={name}>{error.message}</ValidationError>}
      {props.children}
    </FormRow>
  );
};

Input.defaultProps = {
  width: 1,
  type: 'text',
  placeholder: undefined,
  error: {},
};

Input.propTypes = {
  width: number,
  type: string,
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  register: object.isRequired,
  error: object,
};
