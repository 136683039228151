import styled, { css } from 'styled-components';
import { margins, sizes } from '../../styles/variables';
import { media } from '../../styles/mixins';
import { ProductCardRoot } from '../ProductCard/styles';

type ContainerProps = {
  displayOnMobile?: boolean;
  displayOnDesktop?: boolean;
  as?: string;
};

type FullWidthContainerProps = {
  pt?: string;
  pb?: string;
  color?: string;
  noMargin?: boolean;
  noPadding?: boolean;
} & ContainerProps;

type ContainerRootProps = {
  fullWidthMobile?: boolean;
  mergeMarginsWhenGrouped?: boolean;
  pt?: string;
  pb?: string;
  noPadding?: boolean;
  noRightPadding?: boolean;
  noMargin?: boolean;
  noMarginStrong?: boolean;
  displayOnMobile?: boolean;
  displayOnDesktop?: boolean;
  breadcrumb?: boolean;
  swipe?: boolean;
} & ContainerProps;

export const FullWidthContainer = styled.section<FullWidthContainerProps>`
  max-width: none;
  margin-top: 60px;
  margin-bottom: 60px;
  ${media.S`
    margin-top: 80px;
    margin-bottom: 80px;
  `};
  ${media.M`
    margin-top: 120px;
    margin-bottom: 100px;
  `};

  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt} !important;
    `};

  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb} !important;
    `};

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};

  ${(props) =>
    props.noMargin &&
    css`
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    `};

  ${(props) =>
    props.noPadding &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `};

  ${(props) =>
    props.displayOnMobile === false &&
    css`
      display: none;

      ${media.L`
      display: block;
    `};
    `};

  ${(props) =>
    props.displayOnDesktop === false &&
    css`
      display: block;

      ${media.L`
      display: none;
    `};
    `};
`;

export const HorizontalPaddingContainer = styled.div<ContainerProps>`
  padding: 0 ${margins.xSmall}px;
  margin: 0;

  ${media.S`
    padding: 0 ${margins.small}px;
  `}

  ${media.M`
    padding: 0 ${margins.medium}px;
  `}

  ${media.L`
    padding: 0 ${margins.large}px;
  `}

  ${media.XL`
    padding: 0 ${margins.xLarge}px;
  `}

  ${(props) =>
    props.displayOnMobile === false &&
    css`
      display: none;

      ${media.L`
      display: block;
    `};
    `};

  ${(props) =>
    props.displayOnDesktop === false &&
    css`
      display: block;

      ${media.L`
      display: none;
    `};
    `};
`;

export const ContainerRoot = styled.div<ContainerRootProps>`
  margin: ${(props) =>
    props.mergeMarginsWhenGrouped ? '30px auto' : '20px auto'};
  padding: 0 ${margins.xSmall}px;
  max-width: ${sizes.pageMaxWidth}px;

  ${(props) =>
    props.fullWidthMobile &&
    css`
      padding: 0 0;
    `};

  ${media.S`
    padding: 0 ${margins.small}px;

    ${(props) =>
      props.fullWidthMobile &&
      css`
        padding: 0 0;
      `};

      // Fix for rebass Flex grid component breakpoints
      @media screen and (min-width: 40em) {
        padding: 0 ${margins.small}px;
      }
  `}

  ${media.M`
    padding: 0 ${margins.medium}px;
  `}
  

  ${media.L`
    padding: 0 ${margins.large}px;
  `}

  ${media.XL`
    padding: 0 ${margins.xLarge}px;
  `}

  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt} !important;
    `};

  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb} !important;
    `};

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `};

  ${(props) =>
    props.noRightPadding &&
    css`
      padding-right: 0 !important;
    `};

  ${(props) =>
    props.noMargin &&
    css`
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    `};

  ${(props) =>
    props.noMarginStrong &&
    css`
      margin: 0 !important;
    `};

  ${media.IE`
    ${(props) =>
      props.breadcrumb &&
      css`
        /*padding-top: 130px !important;*/
      `};
  `};

  ${(props) =>
    props.displayOnMobile === false &&
    css`
      display: none;

      ${media.L`
      display: block;

      `};
      ${props.mergeMarginsWhenGrouped &&
      css`
        & + ${ContainerRoot} {
          margin-top: -30px;
        }
      `};
    `};

  ${(props) =>
    props.displayOnDesktop === false &&
    css`
      display: block;

      ${props.mergeMarginsWhenGrouped &&
      css`
        & + ${ContainerRoot} {
          margin-top: -30px;
        }
      `};

      ${media.L`
      display: none;

      ${
        props.mergeMarginsWhenGrouped &&
        css`
          & + ${ContainerRoot} {
            margin-top: 30px;
          }
        `
      };
    `};
    `};

  ${(props) =>
    props.mergeMarginsWhenGrouped &&
    css`
      ${ProductCardRoot} {
        margin: 10px 0 0;
      }
    `}
`;
